import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { forkJoin, of } from 'rxjs';
import { shareReplay, take } from 'rxjs/operators';
import { EventsService } from './events.service';
import { GlobalConstService } from './global-const.service';
import { HttpService } from './http.service';
import { InitialConfigService } from './inital-config.service';
import { LanguageService } from './language.service';
import { PlatformService } from './platform.service';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Environment } from 'src/environments/environment';
import { ClientService } from './client.service';
import { Router } from '@angular/router';
import { ToastService } from './toast.service';
import { LoadingService } from './loading.service';
import { SplashScreen } from '@capacitor/splash-screen';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(
    private httpService: HttpService,
    private gConst: GlobalConstService,
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    public events: EventsService,
    private platformService: PlatformService,
    private languageService: LanguageService,
    public initialConfigProvider: InitialConfigService,
    public clientService: ClientService,
    private router: Router,
    private toast: ToastService,
    private loading: LoadingService,
  ) {}

  get loginPage() {
    return Environment.platform === 'web' ? 'login/web' : 'login/first';
  }

  public async loginWithToken(token: string): Promise<any> {
    try {
      await this.afAuth.signInWithCustomToken(token);
    } catch (e) {
      console.log('loginWithToken', e);
    }
  }

  public async signOutFirebase() {
    try {
      await this.afAuth.signOut();
    } catch (e) {
      console.log('signOutFirebase', e);
    }
  }

  public async changeUserStatusInFirebase(userId, status: boolean) {
    console.log('userId', userId);
    try {
      await this.afs.collection('users/').doc(userId.toString()).set({ online: status }, { merge: true });
    } catch (e) {
      console.log('changeUserStatusInFirebase', e);
    }
  }

  public async get(path: string) {
    return await this.afs.doc(path);
  }

  public loginStepOne(username) {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/public/username/${username}/clients`);
  }

  public loginStepTwo(username) {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/public/username/${username}/clients`);
  }

  public getUserLoginType(username, clientId) {

    const dataRequest = {
      username: username,
      clientId: clientId
    };

    return this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/public/user/login-type`, dataRequest);
  }


  public loginStepThree({ username, password, clientId }) {
    //login en tres pasos
    const dataRequest = {
      username: username,
      password: password,
      clientId: clientId
    };

    const request = this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/public/login`, dataRequest);
    request.subscribe({
      next: async (userData) => {
        if (userData.resetPasswordRequested) {
          return userData;
        }
        this.gConst.userData = userData;
        this.saveUserSession(userData);
        this.initialConfigProvider.homePageLoaded = false;
        await this.loginWithToken(userData.account.firebaseToken);
      },
      error: async (error) => {
        console.log(error);

        if(error.status !== 401){
          const text: string = error.errorMessage;
          this.toast.danger(await this.languageService.translate(text.toUpperCase()));
        }
  
      }
    });

    return request;
  }

  public loginAnonymous(clientId) {
    const request = this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/public/anonymous/login`, { clientId });
    request.subscribe(async (userData) => {
      this.gConst.userData = userData;
      this.saveUserSession(userData);
      this.initialConfigProvider.homePageLoaded = false;
      await this.loginWithToken(userData.account.firebaseToken);
    });
    return request;
  }

  public register(user) {
    return this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/public/user`, user);
  }

  public rescuePassword(userData): any {
    const dataRequest = {
      username: userData.username,
      clientId: userData.id
    };
    return this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/public/request-recover-password-email `, dataRequest);
  }

  public logout(allSessions: boolean = false) {
    this.initialConfigProvider.homePageLoaded = false;
    if (allSessions) {
      return this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/logout/all`, {});
    }
    return this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/logout`, {});
  }

  public autoLogin() {
    const userData = this.rescueSessionDataUser();
    if (!userData) {
      return;
    }
    const { rootPages, client, account } = userData;
    this.gConst.userData = { rootPages, client, account };

    return userData;
  }

  public configureSession(res: any) {
    this.loginWithToken(this.gConst.userData.account.firebaseToken);

    const request$ = forkJoin([this.getUserData(), this.getClient(), this.getRootPages()]).pipe(take(1), shareReplay());

    request$.subscribe({
      next: ([account, client, rootPages]) => {
        this.gConst.userData.account = account;
        this.gConst.userData.client = client;
        this.gConst.userData.rootPages = rootPages;

        //OPENEDVAR variable a cambiar por lo que traiga el back
        this.initialConfigProvider.opened.next(client.opened);

        this.updateUserSession({ account, client, rootPages });

        this.languageService.change(account.lang);

        const theme = this.rescueAppTheme();
        this.clientService.setThemeApplication(theme);

        this.events.publish({ name: 'session', value: 'ready' });
      },
      error: (err) => {
        console.log(err);

        this.router.navigate([this.loginPage]).then(() => {
          SplashScreen.hide();       
          this.loading.hide();
        });
      }
    });

    return request$;
  }

  public getUserData() {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/user`);
  }

  private getClient() {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/client`);
  }

  private getRootPages() {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/root_pages`);
  }

  public saveUserSession(userData) {
    localStorage.setItem('userData', JSON.stringify(userData));
  }

  public updateSession(data = {}) {
    return this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/user/session`, data);
  }

  public updateUserSession(userData: any) {
    const savedAccount = this.rescueSessionDataUser().account;
    userData.account = { ...userData.account, firebaseToken: savedAccount.firebaseToken, sessionToken: savedAccount.sessionToken };
    localStorage.setItem('userData', JSON.stringify(userData));
  }

  public updateUserAccountData(account: any) {
    let userData = this.rescueSessionDataUser();
    const savedAccount = userData.account;

    userData.account = { ...account, firebaseToken: savedAccount.firebaseToken, sessionToken: savedAccount.sessionToken };

    localStorage.setItem('userData', JSON.stringify(userData));
  }

  public rescueSessionDataUser() {
    const r = localStorage.getItem('userData');
    return JSON.parse(r);
  }

  public rescueAppTheme() {
    const r = localStorage.getItem('userData');

    return JSON.parse(r).client.style;
  }

  public clearStorage() {
    localStorage.removeItem('userData');
    localStorage.removeItem('cssStyleSheet');
    this.gConst.removeUserData();
    this.initialConfigProvider.clearData();
  }

  public getOAuthURL(tenant, azureClientId, state) {
    let url = `https://login.microsoftonline.com/`;
    url += `${tenant}`;
    url += `/oauth2/v2.0/authorize`;
    url += `?client_id=${azureClientId}`;
    url += `&response_type=code`;
    url += `&redirect_uri=https://auth.empatica.io/oauth/azure/redirect`;
    url += `&response_mode=query`;
    url += `&max_age=0`;
    url += `&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fmail.read`;
    url += `&state=${state}`;
    return url;
  }

  public updateUser(user) {
    return this.httpService.myPatch(`${Environment.API_BASE_URL_V2}/api/empatica/v1/user`, user);
  }
}
