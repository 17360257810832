import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Filters } from 'src/app/models/filters.model';
import { InitConfig } from 'src/app/models/initConfig.model';
//Pages
import { CheckComponentsService } from 'src/app/services/check-components.service';
import { HandlerErrorService } from 'src/app/services/handler-error.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PersonalDocumentsService } from 'src/app/services/personal-document.service';

@Component({
  selector: 'personal-documents',
  templateUrl: 'personal-documents.component.html',
  styleUrls: ['personal-documents.component.scss']
})
export class PersonalDocumentsComponent {
  public docs: Array<File> = [];
  public docsInitConfig: InitConfig;
  public showHeaders: boolean;
  public isEmpty: boolean = false;
  public showViewMoreBtn: boolean = false;

  private errorInComponentDocuments: { haveError: boolean; textError: string } = { haveError: false, textError: '' };

  constructor(
    private router: Router,
    private platform: Platform,
    private handleError: HandlerErrorService,
    private loading: LoadingService,
    private personalDocumentService: PersonalDocumentsService,
    private checkComponent: CheckComponentsService
  ) {
    this.docsInitConfig = personalDocumentService.initConfigPersonalDocuments;
    this.showHeaders = this.docsInitConfig.view.showHeader;
    this.getPersonalDocuments();
  }

  private getPersonalDocuments() {
    const filters: Filters = {
      searchKey: '',
      start: 0,
      orderBy: this.docsInitConfig.view.orderBy,
      orderDir: this.docsInitConfig.view.orderDir,
      length: this.docsInitConfig.view.maxNumItems
    };

    this.personalDocumentService.getPersonalDocuments(this.docsInitConfig.id, filters).subscribe({
      next: (response: InitConfig) => {
        this.showViewMoreBtn = response.items.itemsCount > response.items.itemsFiltered;
        this.docsInitConfig.items = response.items;
        this.docs = this.checkComponent.setItemsInComponent(response.items.itemList, response.items.itemsCount);
        this.isEmpty = this.checkComponent.checkEmptyComponent(this.docs);
      },
      error: (error) => {
        console.error('Error getting documents', error);
        this.errorHandler(error);
      }
    });
  }

  private errorHandler(error) {
    this.errorInComponentDocuments = this.handleError.haveErrorInComponent(error);
  }

  public getDoc(doc) {
    this.personalDocumentService.getDocumentAndDownload(this.docsInitConfig.id, doc.id, true);
  }

  public goToFullDocuments() {
    this.router.navigate([`personal-documents/${this.docsInitConfig.id}/list`]);
  }

  public isListView() {
    return this.docsInitConfig.view.mode === 'item-list' && !this.errorInComponentDocuments.haveError;
  }

  public isButtonView() {
    return this.docsInitConfig.view.mode === 'button' && !this.errorInComponentDocuments.haveError;
  }
}
