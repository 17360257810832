import { Injectable } from '@angular/core';

// Rxjs
import { BehaviorSubject } from 'rxjs';
import { pluck, shareReplay, filter, tap } from 'rxjs/operators';
import { HttpService } from './http.service';
import { Filters } from '../models/filters.model';
import { LoadingService } from './loading.service';

interface State {
  items: Array<any>;
  loading: boolean;
}

@Injectable({ providedIn: 'root' })
export class SearcherService {
  public blogSubject$ = new BehaviorSubject<State>({ items: [], loading: true });
  public likesSubject$ = new BehaviorSubject<State>({ items: [], loading: true });
  public documentSubject$ = new BehaviorSubject<State>({ items: [], loading: true });
  public embeddedSubject$ = new BehaviorSubject<State>({ items: [], loading: true });
  public linkSubject$ = new BehaviorSubject<State>({ items: [], loading: true });
  public contactsSubject$ = new BehaviorSubject<State>({ items: [], loading: true });
  public imageSubject$ = new BehaviorSubject<State>({ items: [], loading: true });
  public calendarSubject$ = new BehaviorSubject<State>({ items: [], loading: true });
  public podcastSubject$ = new BehaviorSubject<State>({ items: [], loading: true });
  public wallSubject$ = new BehaviorSubject<State>({ items: [], loading: true });

  constructor(private httpService: HttpService, private loading: LoadingService) {}

  getState(itemType) {
    return this[itemType + 'Subject$'].getValue();
  }

  public allItems(endpoint: string, filters: Filters, itemType?: string) {
    let url: string = endpoint;

    const request = this.httpService.myGet(url, filters).pipe(shareReplay());

    request.pipe(pluck('items', 'itemList')).subscribe(async (items: Array<any>) => {
      await this.loading.show();
      if (filters.start === 0) {
        items = items;
      } else {
        items = [...this.getState(itemType).items, ...items.filter((item) => !this.getState(itemType).items.some((i) => item.id === i.id))];
      }

      this[itemType + 'Subject$'].next({ items: items, loading: false });
      await this.loading.hide();
    });

    return request;
  }
}
