import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CommentsPage, LikesPage } from '../pages';
import { PipesModule } from '../pipes/pipes.module';
import { AgendaComponent } from './agenda/agenda';
import { AlertModalComponent } from './alert-modal/alert-modal.component';
import { AvatarComponent } from './avatar/avatar.component';
import { BlogItemComponent } from './blog-item/blog-item';
import { BlogComponent } from './blog/blog';
import { CalendarComponent } from './calendar/calendar';
import { EventByMonthComponent } from './calendar/event-by-month/event-by-month';
import { ChangePasswordModalComponent } from './change-password-modal/change-password-modal';
import { ChatListComponent } from './chat-list/chat-list.component';
import { ChatMembersModalComponent } from './chat-members-modal/chat-members-modal.component';
import { ContactsItemComponent } from './contacts-item/contacts-item';
import { ContactsComponent } from './contacts/contacts-component';
import { DocumentsItemComponent } from './documents-item/documents-item-component';
import { DocumentsComponent } from './documents/documents-component';
import { EmbeddedComponent } from './embdedded/embedded.component';
import { EmptyComponent } from './empty/empty';
import { EpisodeComponent } from './episode/episode';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { GalleryComponent } from './gallery/gallery-component';
import { GalleryModalComponent } from './gallery/gallery-modal/gallery-modal';
import { VideoPlayerComponent } from './gallery/video-player/video-player';
import { ImageLoaderComponent } from './image-loader/image-loader.component';
import { ImageModalComponent } from './image-modal/image-modal';
import { InputErrorComponent } from './input-error/input-error';
import { ItemSearcherComponent } from './item-searcher/item-searcher';
import { LikesItemComponent } from './likes-item/likes-item';
import { LinksItemComponent } from './links-item/links-item';
import { LinksComponent } from './links/links';
import { LoadingComponent } from './loading/loading.component';
import { NotifyComponent } from './notify/notify.component';
import { PersonalDocumentsComponent } from './personal-documents/personal-documents.component';
import { PodcastComponent } from './podcast/podcast.component';
import { SurveyComponent } from './survey/survey';
import { TabsComponent } from './tabs/tabs';
import { ToolbarCommentsLikesComponent } from './toolbar-comments-likes/toolbar-comments-likes';
import { UsersFieldsComponent } from './users-fields/users-fields.component';
import { AddWallItemInputComponent } from './wall/add-wall-item-input/add-wall-item-input';
import { NewWallMessageModalComponent } from './wall/new-wall-item-modal/new-wall-item-modal';
import { WallComponent } from './wall/wall';
import { WallItemComponent } from './wall/wall-item/wall-item';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

@NgModule({
  imports: [IonicModule, CommonModule, PipesModule, TranslateModule, FormsModule, ReactiveFormsModule],
  declarations: [
    DocumentsComponent,
    GalleryComponent,
    ContactsComponent,
    GalleryModalComponent,
    ImageModalComponent,
    BlogComponent,
    SurveyComponent,
    CalendarComponent,
    LinksComponent,
    EmptyComponent,
    ChangePasswordModalComponent,
    VideoPlayerComponent,
    ItemSearcherComponent,
    EventByMonthComponent,
    AgendaComponent,
    PersonalDocumentsComponent,
    InputErrorComponent,
    ToolbarCommentsLikesComponent,
    BlogItemComponent,
    EpisodeComponent,
    PodcastComponent,
    DocumentsItemComponent,
    LinksItemComponent,
    LikesItemComponent,
    ContactsItemComponent,
    NotifyComponent,
    LikesPage,
    CommentsPage,
    ChatListComponent,
    ImageLoaderComponent,
    UsersFieldsComponent,
    AvatarComponent,
    LoadingComponent,
    ChatMembersModalComponent,
    TabsComponent,
    AlertModalComponent,
    EmbeddedComponent,
    PodcastComponent,
    ForbiddenComponent,
    WallComponent,
    WallItemComponent,
    AddWallItemInputComponent,
    NewWallMessageModalComponent
  ],
  exports: [
    DocumentsComponent,
    GalleryComponent,
    ContactsComponent,
    GalleryModalComponent,
    ImageModalComponent,
    BlogComponent,
    SurveyComponent,
    CalendarComponent,
    LinksComponent,
    EmptyComponent,
    ChangePasswordModalComponent,
    VideoPlayerComponent,
    ItemSearcherComponent,
    EventByMonthComponent,
    AgendaComponent,
    PersonalDocumentsComponent,
    InputErrorComponent,
    ToolbarCommentsLikesComponent,
    BlogItemComponent,
    EpisodeComponent,
    PodcastComponent,
    DocumentsItemComponent,
    LinksItemComponent,
    LikesItemComponent,
    ContactsItemComponent,
    NotifyComponent,
    LikesPage,
    CommentsPage,
    ChatListComponent,
    ImageLoaderComponent,
    UsersFieldsComponent,
    AvatarComponent,
    LoadingComponent,
    ChatMembersModalComponent,
    TabsComponent,
    AlertModalComponent,
    EmbeddedComponent,
    PodcastComponent,
    ForbiddenComponent,
    AddWallItemInputComponent,
    NewWallMessageModalComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule { }
