import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AlertController, IonNav, MenuController, ModalController, NavController, Platform } from '@ionic/angular';
import { AlertButton, AlertOptions } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, skip, take, tap, shareReplay } from 'rxjs/operators';
import { Environment } from 'src/environments/environment';
import { AlertService } from './services/alert.service';
import { AnalyticsService } from './services/analytics.service';
import { DeviceService } from './services/device.service';
import { EventsService } from './services/events.service';
import { GlobalConstService } from './services/global-const.service';
import { HandlerErrorService } from './services/handler-error.service';
import { InitialConfigService } from './services/inital-config.service';
import { LanguageService } from './services/language.service';
import { LinksService } from './services/links.service';
import { LoadingService } from './services/loading.service';
import { LoginService } from './services/login.service';
import { MessagesService } from './services/messages.service';
import { NetworkService } from './services/network.service';
import { PlatformService } from './services/platform.service';
import { NotificationService } from './services/notification.service';
import { ToastService } from './services/toast.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { ActionPerformed, PermissionStatus, PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications';
import { SimulationService } from './services/simulation.service';
import { ClientService } from './services/client.service';
import { register } from 'swiper/element/bundle';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild(IonNav) nav: IonNav;
  @Output() exit: EventEmitter<any> = new EventEmitter();
  public pages: any;
  public sections = { documents: false, chat: false, directory: false };
  public userData: any;
  public loggedClientheader = null;
  public breadcrumb$: Observable<any>;

  public isMenuAvailable: boolean = false;

  public buttonSelected: string = 'notifications';

  public isNaN = isNaN;

  public desktop$$ = new BehaviorSubject(null);

  public unreadNotifications$: Observable<number>;

  constructor(
    public platform: Platform,
    public translate: TranslateService,
    public gConst: GlobalConstService,
    public events: EventsService,
    private loading: LoadingService,
    public menuCtrl: MenuController,
    public initialConfig: InitialConfigService,
    private pushNotifications: NotificationService,
    private loginService: LoginService,
    private navCtrl: NavController,
    public alertCtrl: AlertController,
    private toast: ToastService,
    public messageProvider: MessagesService,
    private network: NetworkService,
    private handlerErrorProvider: HandlerErrorService,
    private analyticsService: AnalyticsService,
    private router: Router,
    private route: ActivatedRoute,
    private linksService: LinksService,
    private languageService: LanguageService,
    private platformService: PlatformService,
    private zone: NgZone,
    private initialConfigService: InitialConfigService,
    public globalConst: GlobalConstService,
    public alertService: AlertService,
    public modalController: ModalController,
    public deviceService: DeviceService,
    public simulationService: SimulationService,
    public clientService: ClientService,
    @Inject(DOCUMENT) private document: Document
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(Environment.DEFAULT_LANGUAGE);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use(Environment.DEFAULT_LANGUAGE);
  }

  ngOnInit() {
    this.initializeApp();
  }

  private subscribeSimulationMode() {
    this.route.queryParams
      .pipe(
        filter(({ simulation }) => Boolean(simulation)),
        map(({ simulation }) => simulation === 'true')
      )
      .subscribe((simulation) => {
        if (simulation === true) {
          this.simulationService.activateSimulationMode();
        } else {
          this.simulationService.deactivateSimulationMode();
        }
      });
  }

  private async initializeApp() {
    await SplashScreen.show({
      autoHide: false
    });

    this.subscribeDeepLinks();
    this.subscribeSimulationMode();

    this.desktop$$.next(this.platformService.isWeb());

    await this.platform.ready();
    await this.platformService.loadDevice();
    this.setLanguages();
    this.menuCtrl.enable(false);
    const isWindows = this.isWindows();

    if (isWindows) {
      this.document.documentElement.classList.remove('plt-mobile');
      this.document.documentElement.classList.add('plt-desktop');
      this.menuCtrl.enable(true);
    }

    if (!this.network.online()) {
      this.router.navigate(['no-network']);
    } else {
      this.prepareClient();
    }
  }

  isWindows(): boolean {
    if (!window || !navigator) {
      return false;
    }
    const a: string = navigator.userAgent || navigator.vendor || (window as any).opera;

    return /windows/i.test(a);
  }

  get loginPage() {
    return Environment.platform === 'web' ? 'login/web' : 'login/first';
  }

  get initialPage() {
    const openedClient = this.clientService.isOpenedAccountClient();
    let userData = this.loginService.autoLogin();

    if (openedClient) {
      if (userData.account.showProfile === true) {
        return 'home';
      }
      return 'home-public';
    }

    if (!openedClient) {
      if (!userData || userData.account.showProfile === false) {
        return this.loginPage;
      }
      return 'home';
    }

    return 'home';
  }

  prepareClient() {
    const clientId = Environment.CLIENT_ID;

    if (clientId === null) {
      this.clientService.saveAppClient(false);
      this.configureApp();
      return;
    }

    const client = this.clientService.getClient();

    client.subscribe(async (client) => {
      this.clientService.saveAppClient(client.opened);
      this.configureApp();
      this.prepareStyles(client.style);
    });
  }

  prepareStyles(clientStyles) {
    if (clientStyles === null) {
      return;
    }

    this.clientService.saveAppTheme(clientStyles);
    this.clientService.setThemeApplication(clientStyles);
  }

  public configureApp() {
    try {
      this.pushNotifications.initializePushNotifications();

      this.subscribeSessionReady();
      this.subscribeRouterChanges();
      this.languageService.subscribeLangChange();
      this.subscribeSessionExpired();
      this.subscribeNotifications();
      this.subscribeNetworkConnectionLost();

      const params = this.catchTokenFromUrlIfExists();
      const openedClient = this.clientService.isOpenedAccountClient();

      // Su esxiste token en la URL
      if (params !== null) {
        // Logamos con ese token
        this.loginWithUrlParams(params);
        return;
      }

      let userData = null;
      // Si no existe token en la URL
      if (params === null) {
        // Logamos con lo que haya en el local storage
        userData = this.loginService.autoLogin();

        // Si no lo conseguimos lo mandamos para el login
        if (!userData) {
          if (Environment.CLIENT_ID !== null) {
            if (openedClient) {
              this.anonymousLogin();
              return;
            }
            this.router.navigate([this.initialPage]).then(() => {
              SplashScreen.hide();
              this.loading.hide();
            });
            return;
          }

          this.router.navigate([this.loginPage]).then(() => {
            SplashScreen.hide();
            this.loading.hide();
          });

          return;
        }

        if (openedClient && !userData.account.showProfile) {
          this.router.navigate([this.initialPage]).then(() => {
            SplashScreen.hide();
            this.loading.hide();
          });
        }

        console.log('Autologin success');
      }

      const request$ = this.loginService.configureSession(userData);

      request$.subscribe({
        next: () => {},
        error: async (err) => await this.clearSession(),
        complete: () => {}
      });
    } catch (e) {
      console.error('configureApp error -->', e);
    }
  }

  private anonymousLogin() {
    const clientId = Environment.CLIENT_ID;
    const request = this.loginService.loginAnonymous(clientId);

    return request.pipe(tap(() => (this.initialConfig.homePageLoaded = false))).subscribe({
      next: async (response: any) => {
        this.initialConfigService.saveUserData(response);
        this.loginService.saveUserSession(response);

        this.initialConfigService.rootPageComponents = response.rootPageComponents;
        this.initialConfigService.styleSheet = response.client.style;

        await this.loginService.loginWithToken(response.account.firebaseToken);
        await this.getThemeApp();

        this.events.publish({ name: 'session', value: 'ready' });
        //this.menuCtrl.enable(false);
        this.router.navigate([this.initialPage]);
      },
      error: (error) => {
        console.log('Anon login failed so redirect to login page:', error);
        this.router.navigate([this.loginPage]).then(() => SplashScreen.hide());
      }
    });
  }

  private catchTokenFromUrlIfExists() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (!urlParams.has('sid') && !urlParams.has('sessionToken')) {
      return null;
    }

    const sessionToken = urlParams.has('sessionToken') ? urlParams.get('sessionToken') : urlParams.get('sid');
    const source = urlParams.has('utm_source') ? urlParams.get('utm_source') : null;

    return {
      sessionToken: sessionToken,
      sid: sessionToken,
      source: source
    };
  }

  private async loginWithUrlParams(params) {
    await this.clearSession();

    const source = params.source;

    this.loginService.saveUserSession({ account: { sessionToken: params.sessionToken } });

    this.loginService
      .updateSession({ source: source })
      .pipe(take(1))
      .subscribe({
        next: async (response: any) => {
          this.initialConfigService.saveUserData(response);
          this.loginService.saveUserSession(response);
          this.globalConst.userData = response;
          this.initialConfigService.rootPageComponents = response.rootPageComponents;
          this.initialConfigService.styleSheet = response.client.style;

          await this.loginService.loginWithToken(response.account.firebaseToken);
          await this.getThemeApp();

          this.events.publish({ name: 'session', value: 'ready' });
          this.router.navigate([window.location.pathname]).then(() => {
            SplashScreen.hide();
            this.loading.hide();
          });
        },
        error: async (err) => {
          await this.loading.hide();
        }
      });
  }

  private getThemeApp() {
    let cssStyleSheet = this.initialConfigService.styleSheet;
    if (!cssStyleSheet) {
      return;
    }
    this.clientService.saveAppTheme(cssStyleSheet);
    this.clientService.setThemeApplication(cssStyleSheet);
  }

  public subscribeNetworkConnectionLost() {
    this.network.checkConnection();
    this.network.connected$$.pipe(filter((connected) => connected === false)).subscribe(() => this.router.navigate(['no-network']));
    this.network.connected$$
      .pipe(
        skip(1),
        filter((connected) => connected === true)
      )
      .subscribe(() => {
        this.router.navigate(['home']);
      });
  }

  private detectAppClose() {
    const userData = this.loginService.rescueSessionDataUser();
    if (!userData) return;

    this.platform.pause.subscribe(() => this.loginService.changeUserStatusInFirebase(userData.account.id, false));
    this.platform.resume.subscribe(() => this.loginService.changeUserStatusInFirebase(userData.account.id, true));
  }

  private configureSections() {
    if (!this.userData) {
      return;
    }

    // if client not has chats deactivate chat rooms calls to the API
    if (this.sections.chat === true) {
      this.messageProvider.getListOfRooms();
    }

    this.initialConfigService.opened.next(this.userData.client.opened);
  }

  private async setLanguages() {
    this.languageService.change(null);
    moment.locale(Environment.DEFAULT_LANGUAGE);
    moment.updateLocale(Environment.DEFAULT_LANGUAGE, {
      months: await this.languageService.getMonthsTranslate()
    });
  }

  private subscribeSessionExpired() {
    this.events.subscribe({ name: 'session', value: 'expired' }).subscribe(async (event) => {
      await this.clearSession();
      this.toast.info('YOUR-SESSION-HAS-EXPIRED');
      // Si existiera por la muerte de alguna petición
      await this.loading.hide();
      this.router.navigate([this.loginPage]);
    });
  }

  private subscribeRouterChanges() {
    this.breadcrumb$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((e: NavigationEnd) => e.url),
      map((e) => e.split('/').filter((p) => p.length > 0)),
      tap(() => this.events.pages$$.next(null))
    );

    this.events.nav.subscribe((data) => {
      if (!data || !data.route) return;

      this.navCtrl.navigateRoot([data.route]);

      if (data.refreshHome) {
        this.initialConfig.homePageLoaded = false;
      }
    });
  }

  private subscribeSessionReady() {
    this.events.subscribe({ name: 'session', value: 'ready' }).subscribe((events) => {
      this.userData = this.loginService.rescueSessionDataUser();
      this.document.body.classList.add('logged');
      this.document.body.classList.remove('not-logged');

      this.pushNotifications.getToken();
      this.pages = this.userData.rootPages.menu;
      this.loggedClientheader = this.userData.client.style.header;

      const { documents, chat, directory } = this.userData.client.sections;
      this.sections = { documents, chat, directory };

      this.detectAppClose();
      this.configureSections();

      const homePage = this.userData.rootPages.home;
      SplashScreen.hide();
      //this.loading.hide('app component 7');
      this.alertService.showAlerts();

      if (homePage.pageId) {
        this.setHomePage(homePage.pageId);
      } else {
        this.handlerErrorProvider.cmsError();
      }

      this.getNotifications();
      this.askNotificationPermission();
    });
  }

  private async askNotificationPermission() {
    if (this.platformService.isMobile()) {
      const permissions = await PushNotifications.checkPermissions();
      const permissionsStatus: PermissionStatus = await PushNotifications.requestPermissions();
    }
  }

  private setHomePage(pageId) {
    this.initialConfig
      .getHomeConfig(pageId)
      .pipe(take(1))
      .subscribe({
        next: (rootPageComponents) => {
          this.initialConfig.rootPageComponents = rootPageComponents;

          if (this.userData.account.showProfile === false) {
            this.menuCtrl.enable(false);
            this.events.publish({ name: 'anonApp', value: 'ready' });
          } else {
            this.menuCtrl.enable(true);
            this.events.publish({ name: 'app', value: 'ready' });
          }

          if (this.router.url.indexOf('login') !== -1) {
            this.router.navigate(['home']);
          }
        },
        error: (err) => {
          console.error(err);
          SplashScreen.hide();
          this.loading.hide();
        }
      });
  }

  public openMenu() {
    this.menuCtrl.open();
  }

  public openPage(page) {
    console.log('openPage');
    if (!page.url && !page.pageId && !page.target) {
      console.log('Ninguna página asociada al elemento');
      return;
    }
    if (page.url) this.openLinkPage(page);
    else if (page.target) this.openComponent(page);
    else this.openIonicPage(page);
  }

  public openLinkPage(page) {
    console.log('openLinkPage');
    const paramsToAnalytics = { actions: 7 };
    this.analyticsService.registerEvent(paramsToAnalytics, page.url);
    if (page.linkType === 'internal') {
      this.linksService.openInternalLink(page.url);
    } else if (page.linkType === 'external') {
      this.linksService.openExternalLink(page.url);
    } else if (page.linkType === 'embedded') {
      this.linksService.openEmbeddedLink(page.url, page.title);
    } else this.openInternalLink(page.url);
  }

  private openIonicPage(page: any) {
    const homePage = this.loginService.rescueSessionDataUser().rootPages.home;
    if (page.pageId === homePage.pageId) {
      this.router.navigate(['/home']);
    } else {
      this.router.navigate([`/generic/${page.pageId}`]);
    }
  }

  private openComponent(page) {
    const handlerLinkType = this.linksService.linkType[page.targetType];
    handlerLinkType(page);
  }

  public async logout(allSessions = false) {
    this.loginService
      .logout(allSessions)
      .pipe(take(1))
      .subscribe({
        next: async (res) => {
          await this.clearSession();

          this.menuCtrl.close();
          this.menuCtrl.enable(false);

          if (Environment.CLIENT_ID === null) {
            this.router.navigate([this.loginPage]);
            return;
          }

          if (this.initialConfigService.opened.value === true) {
            this.initialConfigService.homePageLoaded = false;
            this.events.publish({ name: 'anonApp', value: 'expired' });
            this.anonymousLogin();
            return;
          }

          this.events.publish({ name: 'app', value: 'expired' });
          this.initialConfigService.homePageLoaded = false;
          this.router.navigate([this.loginPage]);
        },
        error: (err) => console.log(err),

        complete: () => {
          this.loading.hide();
        }
      });
  }

  public async presentAlertLogOut() {
    const alertButtons: AlertButton[] = [
      {
        text: await this.languageService.translate('LOG-OUT-ALL-DEVICES'),
        handler: () => this.logout(true)
      },
      {
        text: await this.languageService.translate('ON-THIS-DEVICE'),
        handler: () => this.logout(false)
      },
      {
        text: await this.languageService.translate('CANCEL'),
        role: 'cancel'
      }
    ];

    const alertOptions: AlertOptions = {
      header: await this.languageService.translate('DO-YOU-WANT-TO-LOG-OUT'),
      buttons: alertButtons
    };

    let alert = await this.alertCtrl.create(alertOptions);

    await alert.present();
  }

  public subscribeNotifications() {
    this.events.notifications.pipe(filter(Boolean)).subscribe(async (notification: any | PushNotificationSchema | ActionPerformed) => {
      const notificationData = notification.data ?? notification.notification.data;

      if (notificationData.topic == 'chat' || notificationData.topic == 'bot') {
        this.goToChat(notificationData);
      } else {
        if (notificationData.target) {
          this.pushNotifications.openNotification(notificationData);
        } else {
          this.router.navigate(['/notifications']);
        }
      }
    });
  }

  private async goToChat(notification: any) {
    const currentUrl = this.router.url;

    if (!notification.idRoom) {
      this.toast.info('Corrupted notification');
      return;
    }

    if (notification.topic === 'bot') {
      if (currentUrl.includes(`chat/chatbot/${notification.idRoom}`)) {
        console.log('User already in conversation');
        return;
      }

      this.router.navigate([`chat/chatbot/${notification.idRoom}`]);
      return;
    }

    if (currentUrl.includes(`/chat/single/${notification.idRoom}`) || currentUrl.includes(`/chat/group/${notification.idRoom}`)) {
      console.log('User already in conversation');
      return;
    }

    const ref = await this.loginService.get('rooms/' + notification.idRoom);
    ref
      .snapshotChanges()
      .pipe(take(1))
      .subscribe(async (ref: any) => {
        const group = ref.payload.data();
        const parts = group.name.split('_');
        const single: boolean = parts.length == 2 && isNaN(parts[0]) === false && isNaN(parts[1]) === false;
        this.router.navigate([`chat/${single ? 'single' : 'group'}/${notification.idRoom}`]);
      });
  }

  private async clearSession() {
    try {
      this.messageProvider.unsubscribeAll();
      await this.loginService.signOutFirebase();
      this.loginService.clearStorage();

      this.document.body.classList.remove('logged');
      this.document.body.classList.add('not-logged');
    } catch (e) {
      console.error(e);
    }
  }

  public async openInternalLink(url: string) {
    this.linksService.openInternalLink(url);
  }

  public changeViewStatus(selected) {
    const segment = selected.detail.value;
    if (this.buttonSelected === segment) return;
    this.buttonSelected = segment;
  }

  back() {
    window.history.back();
  }

  public subscribeDeepLinks() {
    App.addListener('appUrlOpen', (data: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const url = new URL(data.url.toString());
        if (url.pathname !== null) {
          this.router.navigate([url.pathname]);
        }
      });
    });
  }

  private getNotifications() {
    this.unreadNotifications$ = this.pushNotifications.getNotifications().pipe(
      map((res) => res.items.itemsNotViewedCount),
      shareReplay()
    );
  }
}
